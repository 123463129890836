export const utmParams = {
  utm_source: 'utm_source',
  utm_medium: 'utm_medium',
  utm_campaign: 'utm_campaign',
  utm_content: 'utm_content',
  utm_nooverride: 'utm_nooverride',
};

export const refParams = {
  signup: 'signup',
  is_gifted: 'is_gifted',
};

export const cellxpertDataParams = {
  pid: 'pid',
  cxd: 'cxd',
  type: 'type',
};

export const utilityParams = {
  chat: 'chat',
  downloadApk: 'downloadApk',
};

export const promoParams = {
  promo: 'promo',
};
