'use client';

import {
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { CircularProgress } from '@mui/material';
import classNames from 'classnames';

import { useIntercomBootPooling, useIntercomWrap } from '@/blocks/components/Intercom/index';
import styles from '@/blocks/components/Intercom/styles.module.scss';

export function IntercomCustomLauncherContainer({ children }: PropsWithChildren) {
  return <div className={styles.intercomCustomLauncherContainer}>{children}</div>;
}

export function IntercomCustomLauncher() {
  return (
    <IntercomCustomLauncherContainer>
      <IntercomCustomLauncherButton />
    </IntercomCustomLauncherContainer>
  );
}

export function IntercomCustomLauncherButton() {
  return (
    <ShowIntercomBtn
      toggleMode={true}
      loadingComponent={
        <div className={styles.intercomCustomLauncherButtonLoader}>
          <CircularProgress />
        </div>
      }
      id={'intercom_custom_launcher'}
      className={styles.customLauncher}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32">
        <path
          fill={'currentcolor'}
          d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z"
        ></path>
      </svg>
    </ShowIntercomBtn>
  );
}

export function ShowIntercomBtn({
  children,
  className,
  title,
  onClick: propsOnClick,
  loadingComponent = null,
  toggleMode = false,
  ...props
}: HTMLAttributes<HTMLButtonElement> & { loadingComponent?: ReactNode; toggleMode?: boolean }) {
  const { show, isOpen, hide } = useIntercomWrap();
  const { intercomBooted } = useIntercomBootPooling();
  const [isLoading, setIsLoading] = useState(false);
  const wasShowed = useRef(false);

  useEffect(() => {
    if (intercomBooted) {
      window.Intercom('onShow', () => {
        setIsLoading(false);
        wasShowed.current = true;
      });
    }
  }, [intercomBooted]);

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (isOpen && !isLoading && toggleMode) {
        hide();
        return;
      }

      if (!wasShowed.current) {
        setIsLoading(true);
      }

      show();

      if (typeof propsOnClick === 'function') {
        propsOnClick(e);
      }
    },
    [hide, isLoading, isOpen, propsOnClick, show, toggleMode],
  );

  return (
    <button
      {...props}
      className={classNames(className, isLoading && !loadingComponent && styles.loading)}
      onClick={onClick}
      title={isLoading ? 'Loading...' : title}
    >
      {isLoading && loadingComponent ? loadingComponent : children}
      {/*{loadingComponent}*/}
    </button>
  );
}
